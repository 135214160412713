import React from "react";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import ContactForm from "../../components/ContactForm";
import useSiteMetadata from "../../components/SiteMetadata";

function ContactPage() {
  const { companyname } = useSiteMetadata();

    const {
        services,
    } = useSiteMetadata();

  return (
    <Layout className="bg-gray-50">
      <SEO
        title={`Contact Us | ${companyname}`}
        description={`Have some questions you want to ask us? Excellent! We love talking with people to see how we can help you reach your goals. If you have questions about… Our process for making you an all-cash offer…`}
      />

      <div className="container mx-auto">
        <div className="max-w-2xl container p-8 mx-auto">
          <div className="mb-6">
            <h1 className="font-extrabold text-2xl md:text-3xl">
            Connect With Us!
            </h1>
            <p className="text-base md:text-lg text-gray-600">
            
<p>Have some questions you want to ask us?</p>
<p>Excellent! We love talking with people to see how we can help you reach your goals. If you have questions about…</p>
 
<ul className="text-gray-600">
<li>Our process for making you an <strong>all-cash offer for your house</strong></li>
<li>Our process for helping homeowners <a href="/">stop foreclosure in Sacramento</a>&nbsp;if your situation qualifies</li>
<li>Our company and who we are</li>
<li>… or anything else you want to ask us — even just to make sure we’re real people 🙂</li>
</ul>
<p>Just give us a ring at the phone number below or shoot us an email through the form. We’ll get back to you within 48 hours. If you need us quicker, make sure to call as we’re able to answer phone calls more quickly than we’re able to return emails. Chat with you soon!</p>
 



            </p>
          </div>

          <ContactForm fontsize={"text-lg"} />
        </div>
      </div>
    </Layout>
  );
}

export default ContactPage;
